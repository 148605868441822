<template>
<v-row>

        <v-col
        cols="7"
        lg="7"
        md="12"
        sm="12"
        >
            <v-form
                ref="form"

            >
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        >
                        <v-radio-group
                        v-model="radios"
                        v-if="data.type == 1"
                        >
                          <v-radio value="Google">
                            <template v-slot:label>
                              <div>SI </div>
                            </template>
                          </v-radio>
                          <v-radio value="Duckduckgo">
                            <template v-slot:label>
                              <div>NO </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                        <v-card
                          v-else-if="data.type == 2"
                          class="mx-auto"
                          outlined
                          color="transparent"
                          max-width="600"
                        >
                          <v-card-text>
                            <label>Puntaje</label>
                            <v-row
                              class="mb-4"
                              justify="space-between"
                            >
                              <v-col class="text-left">
                                <span
                                  class="text-h2 font-weight-light"
                                  v-text="bpm"
                                />
                                <span class="subheading font-weight-light mr-1"/>
                              </v-col>
                            </v-row>

                            <v-slider
                              v-model="bpm"
                              :color="color"
                              track-color="grey"
                              always-dirty
                              min="0"
                              max="5"
                            >
                              <template v-slot:prepend>
                                <v-icon
                                  :color="color"
                                  @click="decrement"
                                >
                                  mdi-minus
                                </v-icon>
                              </template>

                              <template v-slot:append>
                                <v-icon
                                  :color="color"
                                  @click="increment"
                                >
                                  mdi-plus
                                </v-icon>
                              </template>
                            </v-slider>
                          </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        >
                        <v-textarea
                        name="input-7-1"
                        label="Observacion"
                        hint="Hint text"
                        />
                    </v-col>
                </v-row>

            </v-form>
        </v-col>
        <v-col
        cols="4"
        lg="4"
        md="12"
        sm="12"
        >
            <v-card
              elevation="1"
            >
                <v-card-text class="text-center pt-10 pb-10">
                  <v-icon x-large aria-hidden="false">
                    mdi-camera-outline
                  </v-icon>
                  <div class="text--primary">
                    Vista previa
                  </div>
                </v-card-text>
                <v-divider/>
                <v-col
                cols="12"
                          >
                <v-file-input
                  accept="image/*"
                  label="Adjuntar Archivo"
                />
                </v-col>
            </v-card>
        </v-col>
</v-row>


</template>
<script>
  export default {
    props: {
      title: String,
      data: Object,
      subtitle: String,
      leftBtnTxt: String,
      rightBtnTxt: String,
      leftBtnEvent: Function,
      rightBtnEvent: Function,
    },
    data: () => ({
      bpm: 0,
      interval: null,
      isPlaying: false,
    }),

    computed: {
      color () {
        if (this.bpm < 100) return 'indigo'
        if (this.bpm < 125) return 'teal'
        if (this.bpm < 140) return 'green'
        if (this.bpm < 175) return 'orange'
        return 'red'
      },
      animationDuration () {
        return `${60 / this.bpm}s`
      },
    },

    methods: {
      decrement () {
        this.bpm--
      },
      increment () {
        this.bpm++
      },
      toggle () {
        this.isPlaying = !this.isPlaying
      },
    },
  }
</script>
